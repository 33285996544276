import React, { useMemo, memo } from 'react'
import {
    Grid,
    CircularProgress,
} from '@material-ui/core'
import GroupCarousel from './group'

const ProductListGrouped = memo(function ProductListGrouped({ productsGrouped = [] }) {
    // Use useMemo to determine loading state
    const loading = useMemo(() => {
        return productsGrouped?.length === 0;
    }, [productsGrouped]);

    // Memoize the group rendering to prevent unnecessary re-renders
    const groupCarousels = useMemo(() => {
        return productsGrouped?.map((group, i) => (
            <Grid item xs={12} key={`${group.id}-${i}`}>
                <GroupCarousel group={group} />
            </Grid>
        ));
    }, [productsGrouped]);

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ minHeight: '70vh' }}
            spacing={3}
        >
            {loading && (
                <Grid item>
                    <CircularProgress style={{ color: '#F9D100' }} />
                </Grid>
            )}
            {groupCarousels}
        </Grid>
    )
});

export default ProductListGrouped;