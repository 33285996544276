import React, { Fragment, Suspense, lazy } from 'react'
import ListOfProductsWithData from '../containers/listOfProductsWithData'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Grid } from '@material-ui/core'
const MyOrdersWithData = lazy(() => import('../containers/myOrdersWithData'))

const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        flexGrow: 1,
    },
}))

export const HomePage = () => {
    const classes = useStyles()

    return (
        <Fragment>
            <Grid container item className={classes.root} spacing={1} xs={12} alignItems="center" justify="center">
                <Grid container item xs={12} alignItems="center" justify="center">
                    <Suspense fallback={
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{ minHeight: '200px' }}
                        >
                            <CircularProgress style={{ color: '#F9D100' }} />
                        </Grid>
                    }>
                        <MyOrdersWithData />
                    </Suspense>
                </Grid>
                <Grid container item xs={12} alignItems="center" justify="center">
                    <ListOfProductsWithData publicData={true} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default HomePage